.customer-client {
  &__card {
    .ant-card-body {
      padding: 16px 3px 20px 3px;
    }
  }
  &__list {
    max-height: 60vh;
    height: auto;
    overflow: hidden;
    overflow-y: scroll;
    &__item {
      margin: 10px;
      border-radius: 10px;
      &--selected {
        background: $color-background;
      }
      &:hover, &:active {
        box-shadow: $shadow-M;
        cursor: pointer;
        background: $color-background-hover;
      }
    }
    .ant-list-pagination {
      margin-block-start: 20px;
      margin-block-end: 20px;
    }
  }
}