.activity-form {
  &__form-item {
    margin-bottom: 10px !important;
    .ant-form-item-label {
      padding-bottom: 0px !important;
    }
    &--button {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &__button {
    width: 100%;
  }
}